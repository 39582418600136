import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { AboutSectionContainer } from '@product-site-frontend/shared';

export default function SectionAboutCompany() {
  return (
    <AboutSectionContainer sxContainer={{ mb: { xs: 5, lg: 8 } }}>
      <Typography sx={{ mb: { xs: 4, md: 5, lg: 6 } }} variant="h1">
        О нас
      </Typography>
      <Box>
        <Typography sx={{ fontSize: 22, lineHeight: '38px', mb: 5 }} >
          ООО «АРЕНЗА-ПРО» ( бренд Arenza) — первая в России лизинговая FinTech компания, специализирующаяся на розничном сегменте лизинга оборудования для малого бизнеса стоимостью от 100 000 до 20 000 000 рублей. C момента основания в 2016 году компания профинансировала оборудование на сумму свыше 6,5 млрд руб. По количеству сделок Arenza входит в ТОП-5 компаний по лизингу оборудования в России. При этом компания не просто заключила большое количество лизинговых договоров, а создала собственную уникальную платформу, позволяющую значительно упростить и ускорить процесс финансирования и работу с поставщиками. Инвестором компании является ПАО АФК «Система».
        </Typography>
      </Box>
    </AboutSectionContainer>
  );
}