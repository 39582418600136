import React from 'react';

import { Box, Card, Typography } from '@material-ui/core';
import { AboutSectionContainer } from '@product-site-frontend/shared';

export default function SectionFacts() {
  const facts = [
    { label: 'собственный капитал', value: '> 400 млн руб.' },
    { label: 'лизинговых сделок', value: '> 5 800' },
    { label: 'профинансировано оборудования', value: '> 6,5 млрд руб.' },
  ];

  const styles = {
    card: {
      boxShadow: '0 1px 50px 1px rgba(208, 208, 208, 0.5)',
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      alignItems: 'center',
      justifyContent: 'space-between',
      px: { xs: 3, lg: 9 },
      py: { xs: 4, lg: 8 },
      minHeight: 200,
    },
    box: {
      textAlign: 'center',
    },
    fact: {
      label: {
        fontSize: { xs: 16, lg: 22 },
        fontWeight: 500,
        lineHeight: { xs: 1.1, lg: 1.66 },
      },
      value: {
        color: 'primary.main',
        fontSize: { xs: 28, lg: 40 },
        fontWeight: 900,
        letterSpacing: -0.5,
        lineHeight: { xs: 1.2, lg: 1.35 },
        mb: { xs: 1, lg: 0 },
      },
    }
  };

  return (
    <AboutSectionContainer title="Ключевые факты">
      <Card sx={styles.card}>
        {facts.map(({ label, value }, idx) => (
          <Box
            key={idx}
            sx={{
              ...styles.box,
              mb: {
                xs: idx !== facts.length - 1 ? 4 : 0,
                lg: 0,
              },
            }}
          >
            <Typography sx={styles.fact.value}>
              {value}
            </Typography>
            <Typography sx={styles.fact.label} variant="caption">
              {label}
            </Typography>
          </Box>
        ))}
      </Card>

      <Typography sx={{ marginTop: "18px" }}>* По состоянию на 31.03.2024</Typography>
    </AboutSectionContainer>
  );
}